import React, { useEffect, useCallback } from 'react'
// eslint-disable-next-line
import { useLocation } from '@reach/router'
import qs from 'query-string'
import { navigate } from 'gatsby'
import styled from '@emotion/styled'
import { useManualQuery } from 'graphql-hooks'
import { Input } from '@rebass/forms'
import client from '../graphqlClient'
import { Box, Heading, SrOnly } from '../components/ui'
import { SearchResults, orderByRelevance, GLOBAL_SEARCH_QUERY } from '../components/search'
import { MetaTagsMustHave } from '../components/seo'
import { PageLayoutDefault } from '../components/layout'
import magnifierIcon from '../data/images/icons/icon-magnifier-dark.png'

const StyledInput = styled(Input)`
  padding-left: 32px;
  background: url(${magnifierIcon}) left center / 20px no-repeat;
`

const Search = () => {
  const location = useLocation()
  const searchValue = qs.parse(location.search).q || null
  const [requestSearch, { data, ...searchState }] = useManualQuery(GLOBAL_SEARCH_QUERY, {
    client,
    variables: {
      first: 35,
      search: searchValue,
      mediaSize: 'NORMAL_POST',
    },
  })

  const handleSubmit = useCallback((e) => {
    e.preventDefault()
    navigate(`${location.pathname}?q=${e.target.children[1].value}`, {
      replace: !!searchValue,
    })
  }, [searchValue])

  useEffect(() => {
    if (searchValue) {
      requestSearch()
    }
  }, [searchValue])

  return (
    <>
      <MetaTagsMustHave title="Kereső" />
      <PageLayoutDefault>
        <Box
          mb={4}
          as="form"
          method="get"
          action="/kereso"
          display={['block', 'block', 'block', 'none']}
          onSubmit={(e) => {
            e.persist()
            handleSubmit(e)
          }}
        >
          <SrOnly
            as="label"
            htmlFor="search-term"
          >
            Keress a Tisza-tónál...
          </SrOnly>
          <StyledInput
            name="q"
            type="text"
            id="search-term"
            defaultValue={searchValue}
            placeholder="Keress a Tisza-tónál..."
          />
        </Box>

        {
          !!searchValue && (
            <>
              <Heading
                mb={4}
                as="h1"
              >
                Találatok erre:
                <Box
                  as="i"
                  ml={1}
                >
                  {searchValue}
                </Box>
              </Heading>
              <SearchResults
                {...searchState}
                searchResults={orderByRelevance(
                  data?.results?.nodes ?? [],
                  searchValue,
                )}
              />
            </>
          )
        }
      </PageLayoutDefault>
    </>
  )
}

export default Search
